/* eslint-disable react-hooks/exhaustive-deps */
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ThemeStyle } from "../../styles/theme";
import { AppValidations } from "../../utils/validations";

import { ContainerNewLogin } from "./styles";

type LoginLayoutProps = {
  children: ReactNode;
};

export default function LoginLayout({ children }: LoginLayoutProps) {
  const navigate = useNavigate();

  useEffect(() => {
    ThemeStyle.setTheme();

    const findToken = AppValidations.hasToken();

    if (findToken) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <ContainerNewLogin>
      <div className="leftSide">&nbsp;</div>
      {children}
    </ContainerNewLogin>
  );
}
