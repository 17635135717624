import gsap, { Power4 } from "gsap";

import FormControl from "../../../../Dashboard/Appointments/New/steps/components/FormControl/FormControl";
import Button from "../../../../../Elements/Buttons/Button";

import { ContainerNewLogin } from "./styles";
import useLogic from "./../../hook";

export default function AuthRightRequestPassword() {
  const {
    isLoading,
    dataForm,
    handleChangeForm,
    handleRequestPass,
    showMessageRequestPass,
  } = useLogic();

  function handleBackLogin() {
    const getElement = window.document.querySelector(".right-login");
    const getElementForgot = window.document.querySelector(".right-forgot");
    gsap
      .to(getElementForgot, {
        opacity: 0,
        duration: 0.2,
        ease: Power4.easeIn,
      })
      .then(() => {
        gsap.to(getElementForgot, {
          display: "none",
          duration: 0.1,
          onComplete: () => {
            gsap
              .to(getElement, {
                display: "flex",
                duration: 0.2,
                ease: Power4.easeIn,
              })
              .then(() => {
                gsap.to(getElement, {
                  opacity: 1,
                  duration: 0.2,
                });
              });
          },
        });
      });
  }

  return (
    <ContainerNewLogin className="right-forgot">
      <form autoComplete="off">
        <img
          src="https://i.ibb.co/L8ZpHPF/logo-company.png"
          className="logo-company"
          alt="Logo"
        />
        <h1>Recuperar Senha</h1>
        <div className="contentBoxLogin">
          <FormControl
            keyValue="email"
            label="Email"
            inputType="email"
            style={{ marginBottom: 16 }}
            value={dataForm.email}
            onChange={handleChangeForm}
            placeholder="Seu email"
          />
          {showMessageRequestPass === true ? (
            <p className="request-password">
              Se o e-mail existir, iremos enviar as instruções de recuperação da
              senha.
            </p>
          ) : (
            <></>
          )}
          {showMessageRequestPass === false ? (
            <Button
              label="Continuar"
              isLoading={isLoading}
              onClick={handleRequestPass}
            />
          ) : (
            <></>
          )}
          <div className="text-center">
            Já possui uma conta? <span onClick={handleBackLogin}>Entrar</span>
          </div>
        </div>
      </form>
      <div
        className="content-new-logo"
        // onClick={() => (window.location.href = "https://agendalab.online")}
      >
        &nbsp;
        {/* <img src="https://i.ibb.co/Gt2qghH/agendalab-icon.png" alt="Logo" />
        <span>AgendaLab. &copy;</span> */}
      </div>
    </ContainerNewLogin>
  );
}
