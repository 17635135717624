import React from "react";
import InputField from "../../../../../../../Elements/InputField";

import classes from "./FormControl.module.scss";

type FormControlProps = {
  keyValue: string;
  label: string;
  inputType?: "text" | "number" | "email" | "phone" | "password";
  style?: React.CSSProperties;
  required?: boolean;
  placeholder?: string;
  onChange?: (value: string, key: string) => void;
  value?: string;
  showPass?: boolean;
  setShowPass?: () => void;
};

const FormControl = ({
  label,
  inputType,
  style,
  required,
  placeholder,
  onChange,
  keyValue,
  value,
  showPass,
  setShowPass,
}: FormControlProps) => {
  return (
    <div
      style={{ ...style }}
      className={`${classes.BoxFormControl} cdp-contentFormControl`}
    >
      <div className={`${classes.Label} cdp-contentFormControl-label`}>
        {label}
        {required ? " *" : null}
      </div>
      <div className={`${classes.BoxInput} cdp-contentFormControl-boxInput`}>
        <InputField
          keyValue={keyValue}
          inputType={inputType ?? "text"}
          placeholder={placeholder ?? label}
          value={value}
          onChange={onChange}
        />
      </div>
      {/* {inputType === "password" ? (
        <div className="itemOn">
          {showPass === true ? (
            <div className={classes.BoxIconPassword} onClick={setShowPass}>
              <FaEye />
            </div>
          ) : (
            <div className={classes.BoxIconPassword} onClick={setShowPass}>
              <FaEyeSlash />
            </div>
          )}
        </div>
      ) : null} */}
    </div>
  );
};

export default FormControl;
