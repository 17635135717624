import styled from "styled-components";

export const ContainerNewLogin = styled.div`
  width: 50%;
  height: 100%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;

  &.right-forgot {
    opacity: 0;
    display: none;
  }

  form {
    width: 100%;
    max-width: 400px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .logo-company {
      height: 50px;
      margin-left: 20px;
      margin-top: 70px;
    }
    h1 {
      display: block;
      width: 100%;
      padding-left: 20px;
      margin-top: 30px;
      font-weight: 700;

      font-size: 24px;
    }
  }
  .content-new-logo {
    height: 60px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    opacity: 0.1;

    cursor: pointer;

    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }

    img {
      height: 60px;

      cursor: pointer;
    }
    span {
      font-size: 22px;
      font-weight: 700;
      font-family: "Play", sans-serif;
      color: black;
    }
  }

  .contentBoxLogin {
    width: 100%;
    max-width: 400px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 20px;

    padding-bottom: 26px;

    border-radius: 10px;

    margin-top: 10px;

    .hasError {
      padding: 16px;
      background-color: rgb(253, 237, 237);
      color: rgb(95, 33, 32);

      margin-top: 26px;

      border-radius: 8px;

      cursor: pointer;
      font-size: 14px;
      font-weight: 600;

      svg {
        color: #d32f2f;
      }
    }

    .request-password {
      width: 100%;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .text-center {
      padding-top: 20px;
      font-size: 14px;
      font-weight: 600;
      span {
        color: var(--active-color-hex);
        font-weight: bold;
        cursor: pointer;
      }
    }

    .cdp-contentFormControl {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .cdp-contentFormControl-label {
        margin-bottom: 10px;
        font-weight: bold;
      }

      .cdp-contentFormControl-boxInput {
        width: 100%;
      }
    }
    .content-link-forgot {
      width: 100%;
      text-align: right;
      margin-top: 20px;
      font-size: 14px;
      font-weight: bold;
      color: var(--active-color-hex);
      cursor: pointer;
    }
    .cdp-buttonNormal {
      width: 100%;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 20px;

      padding: 7px 16px;

      font-size: 15px;
      line-height: 17px;
    }
  }

  @media (max-width: 1120px) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .leftSide {
      display: none;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .rightSide {
      width: 100%;
    }

    form {
      .logo-company {
        margin-top: 20px;
      }
    }
  }
`;
