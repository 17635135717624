/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import gsap, { Power4 } from "gsap";

import AuthRightNewPassword from "./container/index";
import { useLocation } from "react-router-dom";
import generalApi from "../../../../services";

export default function NewPassword() {
  const location = useLocation();

  useEffect(() => {
    handleValidIdAndCode();
  }, []);

  function enableForm() {
    const getElement = window.document.querySelector(".right-login");

    gsap
      .to(getElement, {
        display: "flex",
        duration: 0.2,
        ease: Power4.easeIn,
      })
      .then(() => {
        gsap.to(getElement, {
          opacity: 1,
          duration: 0.2,
        });
      });
  }

  function disableForm() {
    const getElement = window.document.querySelector(".contentBoxLogin");
    const getElement3 = window.document.querySelector(".title");

    gsap
      .to([getElement, getElement3], {
        opacity: 0,
        duration: 0.2,
        ease: Power4.easeIn,
      })
      .then(() => {
        gsap.to([getElement, getElement3], {
          display: "none",
          duration: 0.2,
        });
      });

    const getElement2 = window.document.querySelector(".contentBoxLogin");

    gsap
      .to([getElement2, getElement3], {
        display: "flex",
        duration: 0.2,
        ease: Power4.easeIn,
      })
      .then(() => {
        gsap.to([getElement2, getElement3], {
          opacity: 1,
          duration: 0.2,
        });
      });
  }

  async function handleValidIdAndCode() {
    const getArrayIdAndCode = location.pathname.split("/");

    const id = getArrayIdAndCode[2] ? getArrayIdAndCode[2] : null;
    const code = getArrayIdAndCode[3] ? getArrayIdAndCode[3] : null;

    try {
      const doLogin = await generalApi.post("/employee/auth/validate-code", {
        code,
        _id: id,
      });

      if (doLogin.data?.error === false) {
        enableForm();
      } else {
        enableForm();
        disableForm();
      }
    } catch (error) {
      disableForm();
    }
  }

  return <AuthRightNewPassword />;
}
