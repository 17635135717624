/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useSetState from "../../../../../hooks/useSetState";
import generalApi from "../../../../../services";

export type DataFormProps = {
  password?: string;
  confirmPassword?: string;
};

export default function useLogic() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [hasError, setHasError] = useState<string>();

  const [dataForm, setDataForm] = useSetState<DataFormProps>({});

  function handleChangeForm(value: string, key: string) {
    setDataForm({
      [key]: value,
    });
  }

  async function handleSubmit() {
    setIsLoading(true);

    try {
      const getArrayIdAndCode = location.pathname.split("/");

      const id = getArrayIdAndCode[2] ? getArrayIdAndCode[2] : null;
      const code = getArrayIdAndCode[3] ? getArrayIdAndCode[3] : null;

      const doLogin = await generalApi.post("/employee/auth/set-new-password", {
        password: dataForm.password,
        _id: id,
        code,
      });

      if (doLogin.data?.error === false) {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        navigate("/");
      }
    } catch (error) {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    setIsLoading,
    dataForm,
    setDataForm,
    handleChangeForm,
    handleSubmit,
    hasError,
    setHasError,
  };
}
