import { useState } from "react";
import { useNavigate } from "react-router-dom";

import useSetState from "../../../../../hooks/useSetState";
import generalApi from "../../../../../services/index";

export type DataFormProps = {
  email?: string;
  password?: string;
};

const useLogic = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [dataForm, setDataForm] = useSetState<DataFormProps>({});

  const navigate = useNavigate();

  const [isShowPass, setIsShowPass] = useState<boolean>(false);

  const [hasError, setHasError] = useState<string>();

  const [showMessageRequestPass, setShowMessageRequestPass] =
    useState<boolean>(false);

  function handleChangeForm(value: string, key: string) {
    setDataForm({
      [key]: value,
    });
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    setHasError(undefined);
    setIsLoading(true);

    try {
      const doLogin = await generalApi.post("/employee/auth/login", {
        login: dataForm.email,
        password: dataForm.password,
      });

      if (doLogin.data?.error === false) {
        window.localStorage.setItem("cdp_token", doLogin.data?.return?.token);
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
        navigate("/dashboard");
      } else {
        setIsLoading(false);
        setHasError(
          doLogin.data.errorStatus ??
            "Algo deu errado, tente novamente mais tarde."
        );
      }
    } catch (error) {
      setIsLoading(false);
      setHasError("Algo deu errado. Por favor, tente novamente mais tarde.");
    }
  }

  async function handleRequestPass() {
    setHasError(undefined);
    setIsLoading(true);
    setShowMessageRequestPass(false);

    try {
      const doLogin = await generalApi.post("/employee/auth/request-password", {
        login: dataForm.email,
      });

      if (doLogin.data?.error === false) {
        // window.localStorage.setItem("cdp_token", doLogin.data?.return?.token);
        // handleEnableSuccess().then(() => {
        //   setIsLoading(false);
        // });
        setShowMessageRequestPass(true);
      } else {
        setShowMessageRequestPass(false);
        setHasError("Algo deu errado. Por favor, tente novamente mais tarde.");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowMessageRequestPass(false);
      setHasError("Algo deu errado. Por favor, tente novamente mais tarde.");
    }
  }

  return {
    isLoading,
    dataForm,
    handleChangeForm,
    handleSubmit,
    isShowPass,
    setIsShowPass,
    hasError,
    setHasError,
    handleRequestPass,
    showMessageRequestPass,
    navigate,
  };
};

export default useLogic;
