import { useEffect } from "react";
import gsap, { Power4 } from "gsap";

import FormControl from "../../../../Dashboard/Appointments/New/steps/components/FormControl/FormControl";
import Button from "../../../../../Elements/Buttons/Button";

import { ContaineAuthRightLogin } from "./styles";
import useLogic from "./../../hook";

export default function AuthRightLogin() {
  const {
    isLoading,
    dataForm,
    handleChangeForm,
    handleSubmit,
    hasError,
    setHasError,
    navigate,
  } = useLogic();

  useEffect(() => {
    const getElement = window.document.querySelector(".right-login");

    gsap.to(getElement, {
      display: "flex",
      duration: 0.1,
      onComplete: () => {
        gsap
          .to(getElement, {
            display: "flex",
            duration: 0.2,
            ease: Power4.easeIn,
          })
          .then(() => {
            gsap.to(getElement, {
              opacity: 1,
              duration: 0.2,
            });
          });
      },
    });
  }, []);

  function onTapRequestPassword() {
    const getElement = window.document.querySelector(".right-login");
    const getElementForgot = window.document.querySelector(".right-forgot");

    gsap
      .to(getElement, {
        opacity: 0,
        duration: 0.2,
        ease: Power4.easeIn,
      })
      .then(() => {
        gsap.to(getElement, {
          display: "none",
          duration: 0.1,
          onComplete: () => {
            gsap
              .to(getElementForgot, {
                display: "flex",
                duration: 0.2,
                ease: Power4.easeIn,
              })
              .then(() => {
                gsap.to(getElementForgot, {
                  opacity: 1,
                  duration: 0.2,
                });
              });
          },
        });
      });
  }

  return (
    <ContaineAuthRightLogin className="right-login">
      <form onSubmit={handleSubmit}>
        <img
          src="https://i.ibb.co/L8ZpHPF/logo-company.png"
          className="logo-company"
          alt="Logo"
        />
        <h1>Entrar</h1>
        <div className="contentBoxLogin">
          <FormControl
            keyValue="email"
            label="Email"
            inputType="email"
            style={{ marginBottom: 16 }}
            value={dataForm.email}
            onChange={handleChangeForm}
            placeholder="Seu email"
          />
          <FormControl
            keyValue="password"
            label="Senha"
            inputType="password"
            value={dataForm.password}
            onChange={handleChangeForm}
            placeholder="Sua senha"
          />
          {hasError ? (
            <div
              className="hasError"
              onClick={() => {
                setHasError(undefined);
              }}
            >
              {hasError}
            </div>
          ) : null}
          <div onClick={onTapRequestPassword} className="content-link-forgot">
            Recuperar minha senha
          </div>
          <Button type="submit" label="Entrar" isLoading={isLoading} />
        </div>
      </form>
      <div
        className="content-new-logo"
        onClick={() => {
          navigate("/politica-de-privacidade");
        }}
      >
        &nbsp;
        {/* <img src="https://i.ibb.co/Gt2qghH/agendalab-icon.png" alt="Logo" /> */}
        <span>Política de Privacidade & Termos e Condições.</span>
      </div>
    </ContaineAuthRightLogin>
  );
}
