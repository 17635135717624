import moment from "moment";

moment.locale("en", {
    relativeTime: {
        future: "em %s",
        past: "%s atrás",
        s: "segundos",
        m: "um minuto",
        mm: "%d m",
        h: "uma hora",
        hh: "%d h",
        d: "um dia",
        dd: "%d dias",
        M: "um mês",
        MM: "%d meses",
        y: "um ano",
        yy: "%d anos",
    },
    months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
    ],
    weekdays: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
        "Domingo",
    ],
    weekdaysShort: "Dom._Seg._Ter._Qua._Qui._Sex._Sab.".split("_"),
    weekdaysMin: "Do_Se_Te_Qa_Qi_Se_Sa".split("_"),
    weekdaysParseExact: true,
});
