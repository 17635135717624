import LoginLayout from "../../../layouts/LoginLayout";
import AuthRightLogin from "./NewLogin/containers/login";
import AuthRightRequestPassword from "./NewLogin/containers/request-password";

const LoginWrapper = () => {
  return (
    <LoginLayout>
      <AuthRightLogin />
      <AuthRightRequestPassword />
    </LoginLayout>
  );
};

export default LoginWrapper;
