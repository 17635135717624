import FormControl from "../../../Dashboard/Appointments/New/steps/components/FormControl/FormControl";
import Button from "../../../../Elements/Buttons/Button";

import { ContaineAuthRightLogin } from "./styles";
import useLogic from "./useLogic";

export default function AuthRightNewPassword() {
  const {
    isLoading,
    dataForm,
    handleChangeForm,
    handleSubmit,
    hasError,
    setHasError,
  } = useLogic();

  return (
    <ContaineAuthRightLogin className="right-login">
      <form autoComplete="off">
        <img
          src="https://i.ibb.co/L8ZpHPF/logo-company.png"
          className="logo-company"
          alt="Logo"
        />
        <h1 className="title">Entrar</h1>
        <div className="contentBoxLogin">
          <FormControl
            keyValue="password"
            label="Senha"
            inputType="password"
            style={{ marginBottom: 16 }}
            value={dataForm.password}
            onChange={handleChangeForm}
            placeholder="Nova enha"
          />
          <FormControl
            keyValue="confirmPassword"
            label="Repetir Senha"
            inputType="password"
            value={dataForm.confirmPassword}
            onChange={handleChangeForm}
            placeholder="Sua senha"
          />
          {hasError ? (
            <div
              className="hasError"
              onClick={() => {
                setHasError(undefined);
              }}
            >
              {hasError}
            </div>
          ) : null}
          <br />
          <Button
            label="Salvar Senha"
            isLoading={isLoading}
            onClick={handleSubmit}
          />
        </div>
        <div className="invalid-box-code">Dados inválidos.</div>
      </form>
      <div
        className="content-new-logo"
        // onClick={() => (window.location.href = "https://agendalab.online")}
      >
        &nbsp;
        {/* <img src="https://i.ibb.co/Gt2qghH/agendalab-icon.png" alt="Logo" />
        <span>AgendaLab. &copy;</span> */}
      </div>
    </ContaineAuthRightLogin>
  );
}
