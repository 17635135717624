import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { MobileContextProvider } from "../contexts/MobileContext";

import LoginWrapper from "../components/Wrappers/Login/LoginWrapper";
import NewPasswordWrapper from "../components/Wrappers/Login/NewPasswordWrapper";
import PolicyWrapper from "../components/Wrappers/Login/PolicyPrivacy";

const PrivateRoutes = React.lazy(() => import("./Private"));

const DefaultRoutes = () => {
  return (
    <MobileContextProvider>
      <Suspense fallback={<></>}>
        <Routes>
          <Route
            path="/*"
            element={
              <Routes>
                <Route index element={<LoginWrapper />} />
                <Route
                  path="new-password/:id/:code"
                  element={<NewPasswordWrapper />}
                />
                <Route
                  path="politica-de-privacidade"
                  element={<PolicyWrapper />}
                />
                <Route path="/*" element={<PrivateRoutes />} />
              </Routes>
            }
          />
        </Routes>
      </Suspense>
    </MobileContextProvider>
  );
};

export default DefaultRoutes;
