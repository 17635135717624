import LoginLayout from "../../../layouts/LoginLayout";
import NewPassword from "./NewPassword";

const NewPasswordWrapper = () => {
  return (
    <LoginLayout>
      <NewPassword />
    </LoginLayout>
  );
};

export default NewPasswordWrapper;
