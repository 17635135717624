import React, { MouseEventHandler, ReactNode } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import classes from "./Buttons.module.scss";
import { FaDownload, FaPlus } from "react-icons/fa";
import { FiCheck } from "react-icons/fi";

type ButtonOutlineProps = {
  label: string;
  icon?: string | React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: React.CSSProperties;
  isLoading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  checked?: boolean;
  type?: "button" | "submit";
};

const Button = ({
  label,
  icon,
  onClick,
  style,
  isLoading,
  disabled,
  children,
  checked,
  type,
}: ButtonOutlineProps) => {
  return (
    <button
      className={[
        classes.ButtonNormal,
        disabled ? classes.Disabled : null,
        icon !== "+" ? classes.Iconed : null,
        "cdp-buttonNormal",
      ].join(" ")}
      type={type ?? "button"}
      onClick={(event) => {
        if (!disabled) {
          onClick && onClick(event);
        }
      }}
      style={style}
    >
      {children ?? (
        <>
          {!checked ? (
            <>
              {isLoading ? (
                <CircularProgress size={16} />
              ) : (
                <>
                  {icon && icon === "+" ? (
                    <span>{icon}</span>
                  ) : icon === "FaDownload" ? (
                    <div style={{ marginRight: 10 }}>
                      <FaDownload />
                    </div>
                  ) : icon === "FaPlus" ? (
                    <div style={{ marginRight: 10 }}>
                      <FaPlus />
                    </div>
                  ) : null}
                  {label}
                </>
              )}
            </>
          ) : (
            <FiCheck size={20} />
          )}
        </>
      )}
    </button>
  );
};

export default Button;
