const PolicyWrapper = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          width: "80%",
          padding: "50px",
        }}
      >
        <h2>
          <span>Política Privacidade</span>
        </h2>
        <br />
        <p>
          <span>
            A sua privacidade é importante para nós. É política do Cia das
            Persianas - ADM respeitar a sua privacidade em relação a qualquer
            informação sua que possamos coletar no site{" "}
            <a href="https://cdp-webapp.com/">Cia das Persianas - ADM</a>, e
            outros sites que possuímos e operamos.
          </span>
        </p>
        <p>
          <span>
            Solicitamos informações pessoais apenas quando realmente precisamos
            delas para lhe fornecer um serviço. Fazemo-lo por meios justos e
            legais, com o seu conhecimento e consentimento. Também informamos
            por que estamos coletando e como será usado.
          </span>
        </p>
        <p>
          <span>
            Apenas retemos as informações coletadas pelo tempo necessário para
            fornecer o serviço solicitado. Quando armazenamos dados, protegemos
            dentro de meios comercialmente aceitáveis ​​para evitar perdas e
            roubos, bem como acesso, divulgação, cópia, uso ou modificação não
            autorizados.
          </span>
        </p>
        <p>
          <span>
            Não compartilhamos informações de identificação pessoal publicamente
            ou com terceiros, exceto quando exigido por lei.
          </span>
        </p>
        <p>
          <span>
            O nosso site pode ter links para sites externos que não são operados
            por nós. Esteja ciente de que não temos controle sobre o conteúdo e
            práticas desses sites e não podemos aceitar responsabilidade por
            suas respectivas&nbsp;
          </span>
          políticas de privacidade
          <span>.</span>
        </p>
        <p>
          <span>
            Você é livre para recusar a nossa solicitação de informações
            pessoais, entendendo que talvez não possamos fornecer alguns dos
            serviços desejados.
          </span>
        </p>
        <p>
          <span>
            O uso continuado de nosso site será considerado como aceitação de
            nossas práticas em torno de privacidade e informações pessoais. Se
            você tiver alguma dúvida sobre como lidamos com dados do usuário e
            informações pessoais, entre em contacto connosco.
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <h3>
          <span>Compromisso do Usuário</span>
        </h3>
        <p>
          <span>
            O usuário se compromete a fazer uso adequado dos conteúdos e da
            informação que o Cia das Persianas - ADM oferece no site e com
            caráter enunciativo, mas não limitativo:
          </span>
        </p>
        <ul>
          <li>
            <span>
              A) Não se envolver em atividades que sejam ilegais ou contrárias à
              boa fé a à ordem pública;
            </span>
          </li>
          <li>
            <span>
              B) Não difundir propaganda ou conteúdo de natureza racista,
              xenofóbica,{" "}
            </span>
            <span>bbebbet</span>
            <span>
              {" "}
              ou azar, qualquer tipo de pornografia ilegal, de apologia ao
              terrorismo ou contra os direitos humanos;
            </span>
          </li>
          <li>
            <span>
              C) Não causar danos aos sistemas físicos (hardwares) e lógicos
              (softwares) do Cia das Persianas - ADM, de seus fornecedores ou
              terceiros, para introduzir ou disseminar vírus informáticos ou
              quaisquer outros sistemas de hardware ou software que sejam
              capazes de causar danos anteriormente mencionados.
            </span>
          </li>
        </ul>
        <h3>
          <span>Mais informações</span>
        </h3>
        <p>
          <span>
            Esperemos que esteja esclarecido e, como mencionado anteriormente,
            se houver algo que você não tem certeza se precisa ou não,
            geralmente é mais seguro deixar os cookies ativados, caso interaja
            com um dos recursos que você usa em nosso site.
          </span>
        </p>
        <p>
          <span>
            Esta política é efetiva a partir de&nbsp;2 December 2023 09:24
          </span>
        </p>
        <br />
        <br />
        <br />
        <h2>
          <span>1. Termos</span>
        </h2>
        <p>
          <span>
            Ao acessar ao site{" "}
            <a href="https://cdp-webapp.com/">Cia das Persianas - ADM</a>,
            concorda em cumprir estes termos de serviço, todas as leis e
            regulamentos aplicáveis ​​e concorda que é responsável pelo
            cumprimento de todas as leis locais aplicáveis. Se você não
            concordar com algum desses termos, está proibido de usar ou acessar
            este site. Os materiais contidos neste site são protegidos pelas
            leis de direitos autorais e marcas comerciais aplicáveis.
          </span>
        </p>
        <h2>
          <span>2. Uso de Licença</span>
        </h2>
        <p>
          <span>
            É concedida permissão para baixar temporariamente uma cópia dos
            materiais (informações ou software) no site Cia das Persianas - ADM
            , apenas para visualização transitória pessoal e não comercial. Esta
            é a concessão de uma licença, não uma transferência de título e, sob
            esta licença, você não pode:&nbsp;
          </span>
        </p>
        <ol>
          <li>
            <span>modificar ou copiar os materiais;&nbsp;</span>
          </li>
          <li>
            <span>
              usar os materiais para qualquer finalidade comercial ou para
              exibição pública (comercial ou não comercial);&nbsp;
            </span>
          </li>
          <li>
            <span>
              tentar descompilar ou fazer engenharia reversa de qualquer
              software contido no site Cia das Persianas - ADM;&nbsp;
            </span>
          </li>
          <li>
            <span>
              remover quaisquer direitos autorais ou outras notações de
              propriedade dos materiais; ou&nbsp;
            </span>
          </li>
          <li>
            <span>
              transferir os materiais para outra pessoa ou 'espelhe' os
              materiais em qualquer outro servidor.
            </span>
          </li>
        </ol>
        <p>
          <span>
            Esta licença será automaticamente rescindida se você violar alguma
            dessas restrições e poderá ser rescindida por Cia das Persianas -
            ADM a qualquer momento. Ao encerrar a visualização desses materiais
            ou após o término desta licença, você deve apagar todos os materiais
            baixados em sua posse, seja em formato eletrónico ou impresso.
          </span>
        </p>
        <h2>
          <span>3. Isenção de responsabilidade</span>
        </h2>
        <ol>
          <li>
            <span>
              Os materiais no site da Cia das Persianas - ADM são fornecidos
              'como estão'. Cia das Persianas - ADM não oferece garantias,
              expressas ou implícitas, e, por este meio, isenta e nega todas as
              outras garantias, incluindo, sem limitação, garantias implícitas
              ou condições de comercialização, adequação a um fim específico ou
              não violação de propriedade intelectual ou outra violação de
              direitos.
            </span>
          </li>
          <li>
            <span>
              Além disso, o Cia das Persianas - ADM não garante ou faz qualquer
              representação relativa à precisão, aos resultados prováveis ​​ou à
              confiabilidade do uso dos materiais em seu site ou de outra forma
              relacionado a esses materiais ou em sites vinculados a este site.
            </span>
          </li>
        </ol>
        <h2>
          <span>4. Limitações</span>
        </h2>
        <p>
          <span>
            Em nenhum caso o Cia das Persianas - ADM ou seus fornecedores serão
            responsáveis ​​por quaisquer danos (incluindo, sem limitação, danos
            por perda de dados ou lucro ou devido a interrupção dos negócios)
            decorrentes do uso ou da incapacidade de usar os materiais em Cia
            das Persianas - ADM, mesmo que Cia das Persianas - ADM ou um
            representante autorizado da Cia das Persianas - ADM tenha sido
            notificado oralmente ou por escrito da possibilidade de tais danos.
            Como algumas jurisdições não permitem limitações em garantias
            implícitas, ou limitações de responsabilidade por danos conseqüentes
            ou incidentais, essas limitações podem não se aplicar a você.
          </span>
        </p>
        <h2>
          <span>5. Precisão dos materiais</span>
        </h2>
        <p>
          <span>
            Os materiais exibidos no site da Cia das Persianas - ADM podem
            incluir erros técnicos, tipográficos ou fotográficos. Cia das
            Persianas - ADM não garante que qualquer material em seu site seja
            preciso, completo ou atual. Cia das Persianas - ADM pode fazer
            alterações nos materiais contidos em seu site a qualquer momento,
            sem aviso prévio. No entanto, Cia das Persianas - ADM não se
            compromete a atualizar os materiais.
          </span>
        </p>
        <h2>
          <span>6. Links</span>
        </h2>
        <p>
          <span>
            O Cia das Persianas - ADM não analisou todos os sites vinculados ao
            seu site e não é responsável pelo conteúdo de nenhum site vinculado.
            A inclusão de qualquer link não implica endosso por Cia das
            Persianas - ADM do site. O uso de qualquer site vinculado é por
            conta e risco do usuário.
          </span>
        </p>
        <p>
          <br />
        </p>
        <h3>
          <span>Modificações</span>
        </h3>
        <p>
          <span>
            O Cia das Persianas - ADM pode revisar estes termos de serviço do
            site a qualquer momento, sem aviso prévio. Ao usar este site, você
            concorda em ficar vinculado à versão atual desses termos de serviço.
          </span>
        </p>
        <h3>
          <span>Lei aplicável</span>
        </h3>
        <p>
          <span>
            Estes termos e condições são regidos e interpretados de acordo com
            as leis do Cia das Persianas - ADM e você se submete
            irrevogavelmente à jurisdição exclusiva dos tribunais naquele estado
            ou localidade.
          </span>
        </p>
      </div>
    </div>
  );
};

export default PolicyWrapper;
