import { createRoot } from "react-dom/client";

import App from "./App";

import "./moment";

import "./styles/tooltip.scss";
import "./index.scss";

const domNode = document.getElementById("root") as HTMLElement;

const root = createRoot(domNode);

root.render(<App />);
