import React from "react";
import InputMask from "react-input-mask";

import classes from "./InputField.module.scss";

type InputFieldProps = {
  keyValue?: string;
  placeholder?: string;
  inputType?:
    | "text"
    | "number"
    | "email"
    | "phone"
    | "radio"
    | "date"
    | "time"
    | "textarea"
    | "password";
  value?: string | Date;
  onChange?: (value: string, key: string) => void;
  active?: boolean;
  readOnly?: boolean;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  classNames?: string;
};

const InputField = ({
  keyValue,
  inputType,
  placeholder,
  onChange,
  value,
  active,
  readOnly,
  onClick,
  onFocus,
  onBlur,
  classNames,
}: InputFieldProps) => {
  return (
    <>
      {inputType === "phone" ? (
        <InputMask
          mask={"(99) 99999-9999"}
          type="text"
          placeholder={placeholder}
          maskPlaceholder={null}
          className={classes.InputField}
          // @ts-ignore
          value={value}
          onChange={(event) => {
            onChange && onChange(`${event.target.value}`, `${keyValue}`);
          }}
          onClick={onClick}
          autoComplete="off"
        />
      ) : inputType === "radio" ? (
        <div
          className={[
            classes.InputRadio,
            active === true ? classes.Selected : undefined,
          ].join(" ")}
        >
          &nbsp;
        </div>
      ) : (
        <>
          {inputType === "textarea" ? (
            <textarea
              // @ts-ignore
              value={value}
              type={inputType ?? "text"}
              className={[classes.InputField, classNames].join(" ")}
              placeholder={placeholder}
              onChange={(event) => {
                onChange && onChange(`${event.target.value}`, `${keyValue}`);
              }}
              readOnly={readOnly}
              onClick={onClick}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          ) : (
            <input
              // @ts-ignore
              value={value}
              type={inputType ?? "text"}
              className={[classes.InputField, "InputField"].join(" ")}
              placeholder={placeholder}
              onChange={(event) => {
                onChange && onChange(`${event.target.value}`, `${keyValue}`);
              }}
              readOnly={readOnly}
              onClick={onClick}
              onFocus={onFocus}
              onBlur={onBlur}
              autoComplete="off"
              autoCorrect="off"
            />
          )}
        </>
      )}
    </>
  );
};

export default InputField;
